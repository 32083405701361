import { Loader, flexStyles, paddingStyles } from "ui";
import { updateViewedItems, useForms } from "../../store";
import { Button, Overlay } from "../ui";
import { FormCard } from "./FormCard";
import classNames from "classnames";
import { Outlet } from "react-router";
import { useEffect } from "react";

export function List() {
  const { forms, loading } = useForms();

  useEffect(() => {
    if (forms.length > 0) {
      updateViewedItems({ formAnswers: true });
    }
  }, [forms]);

  if (forms.length === 0 && loading) {
    return <Loader />;
  }

  return (
    <Overlay className={classNames(paddingStyles.p125, flexStyles.gap125)} header="Forms" backTo={{ to: "/more" }}>
      <div className={classNames(flexStyles.horiz, flexStyles.justifyEnd)}>
        <Button to="/forms/new">+ New Form</Button>
      </div>
      {forms.map((form) => (
        <FormCard key={form.id} form={form} />
      ))}
      <Outlet />
    </Overlay>
  );
}
