import { FormProvider, FormTag, Icon, Loader, TextMain, flexStyles, iconStyles, paddingStyles } from "ui";
import { useBackLocation } from "../../hooks";
import { Progress } from "./Progress";
import { updateContactImport, useContactGroups, useContactImport } from "../../store";
import { ContactImportPayload, ContactImportSchema } from "schemas";
import { SelectableContactGroup } from "../contactGroups";
import { BottomWrapper, Overlay, Submit, SuperCard } from "../ui";
import { Outlet, useNavigate } from "react-router";

export function ChooseLists() {
  const navigate = useNavigate();
  const { contactImport, loading } = useContactImport();
  const { contactGroups } = useContactGroups(["AllContacts", "Unconfirmed"]);
  const backLocation = useBackLocation("/contacts");

  if (loading || !contactImport) {
    return <Loader />;
  }

  const onSubmit = async (payload: ContactImportPayload) => {
    await updateContactImport({ contactImportId: contactImport.id, payload });
    const next = contactImport.type === "csv" ? "summary" : "primer";
    navigate(`../${next}`, { relative: "path" });
  };

  return (
    <Overlay
      header="Contact import"
      backTo={{ to: backLocation }}
      title="Select Destination Contact Lists"
      subtitle={<TextMain>Optionally select groups to import into</TextMain>}
      subHeaderElement={<Progress current={1} contactImportType={contactImport.type} />}
    >
      <div className={paddingStyles.p125}>
        <FormProvider defaultValues={contactImport} schema={ContactImportSchema}>
          <FormTag<ContactImportPayload> onSubmit={onSubmit} className={flexStyles.gap050}>
            {contactGroups.map((contactGroup) => (
              <SelectableContactGroup
                key={`selectable-group-${contactGroup.id}`}
                name="destinationContactGroupIds"
                contactGroup={contactGroup}
              />
            ))}
            <SuperCard
              to="addNewList"
              left={<Icon className={iconStyles.neutral600} iconType="Plus" />}
              title={<TextMain>Create new</TextMain>}
            />

            <BottomWrapper sticky>
              <Submit label="Continue" />
            </BottomWrapper>
          </FormTag>
        </FormProvider>
      </div>
      <Outlet />
    </Overlay>
  );
}
