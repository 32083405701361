import { LiteDomain } from "schemas";
import { apiRequest } from "../apiRequest";
import { HookLoading } from "../types";
import { useEffect, useState } from "react";

async function getDomains() {
  try {
    const { domains } = await apiRequest<{ domains: LiteDomain[] }>({ url: `domains` });
    return domains;
  } catch {
    // not found
  }
}

export function useDomains(): HookLoading<{ domains: LiteDomain[] }> {
  const [domains, setDomains] = useState<LiteDomain[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDomains().then((domains) => {
      if (domains) {
        setDomains(domains);
      }
      setLoading(false);
    });
  }, []);

  return { loading, domains };
}
