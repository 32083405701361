import { Loader, Progress, TextMain, flexStyles, paddingStyles } from "ui";
import { useIdParam } from "../../hooks";
import { generateCampaignFromSubject, useGenerateSubjects } from "../../store";
import { useState } from "react";
import { useNavigate } from "react-router";
import { Button, Overlay, RadioCard } from "../ui";

export function ChooseSubject() {
  const navigate = useNavigate();
  const [generating, setGenerating] = useState(false);
  const id = useIdParam("promptId");
  const { loading, subjects } = useGenerateSubjects({ id });
  const [selectedSubject, setSelectedSubject] = useState<string | null>(null);

  const onNext = async () => {
    if (selectedSubject) {
      setGenerating(true);
      const { campaign } = await generateCampaignFromSubject(id, { subject: selectedSubject });
      navigate(`/campaigns/generate/${id}/preview/${campaign.id}`);
      setGenerating(false);
    }
  };

  return (
    <Overlay
      backTo={{ to: `/campaigns/generate/${id}`, label: "Prompt" }}
      className={paddingStyles.p125}
      header="Create an email campaign with 🤖"
      subtitle={<TextMain>{generating ? "Generating your content" : "Choose a subject for your campaign"}</TextMain>}
      subHeaderElement={<Progress current={2} total={3} />}
    >
      {loading || generating ? (
        <Loader />
      ) : (
        <div className={flexStyles.vert125}>
          {subjects.map((subject) => (
            <RadioCard
              title={subject}
              key={subject}
              checked={selectedSubject === subject}
              onClick={async () => setSelectedSubject(subject)}
            />
          ))}
          <Button disabled={!selectedSubject} onClick={onNext}>
            Next
          </Button>
        </div>
      )}
    </Overlay>
  );
}
