import { Loader, Strong, TextMain, flexStyles } from "ui";
import classNames from "classnames";
import { useLocation } from "react-router";
import { LogoHeader, Overlay, Primer } from "../ui";
import { useContactImport, useGoogleAuthUrl } from "../../store";
import { useBackLocation } from "../../hooks";
import { Progress } from "./Progress";

export function GooglePrimer() {
  const { pathname } = useLocation();
  const { contactImport, loading } = useContactImport();
  const googleAuthUrl = useGoogleAuthUrl({
    type: "contacts",
    payload: { errorRedirect: pathname, contactImportId: contactImport?.id },
  });
  const backLocation = useBackLocation("/contacts");

  if (!googleAuthUrl || loading || !contactImport) {
    return <Loader full />;
  }

  return (
    <Overlay
      header="Contact import"
      backTo={{ to: backLocation }}
      title="Connect with Google"
      subHeaderElement={<Progress current={2} contactImportType={contactImport.type} />}
      full
    >
      <div className={classNames(flexStyles.grow, flexStyles.vert)}>
        <LogoHeader logo="GoogleContacts" tall />
        <Primer
          header="Connect your Google Contacts"
          description={
            <TextMain>
              PopSend uses <Strong>Google Contacts</Strong> to import your contacts easily.
            </TextMain>
          }
          why="Why should I import Google Contacts?"
          tableRows={["Import your entire address book to PopSend", "Expand the reach of your email campaigns"]}
          minutes={2}
          continueTo={googleAuthUrl}
          cta="Connect Google Contacts"
          cancelTo={backLocation}
        />
      </div>
    </Overlay>
  );
}
