import { GrayGrow, ResponsiveWrapper, flexStyles, heightStyles, paddingStyles } from "ui";
import { NoResults } from "../contacts/NoResults";
import classNames from "classnames";
import { Outlet } from "react-router";
import { useCurrentBusiness } from "../../store";

export function AddYourFirstContact() {
  const { allContactsGroupId } = useCurrentBusiness();

  return (
    <GrayGrow>
      <ResponsiveWrapper className={classNames(paddingStyles.p125, flexStyles.vert, heightStyles.full)}>
        <NoResults newContactTo={`/contactGroups/${allContactsGroupId}/add`} />
      </ResponsiveWrapper>
      <Outlet />
    </GrayGrow>
  );
}
