import { HobDomainPayload, HobDomainSchema } from "schemas";
import { FormProvider, FormTag, TextInput } from "ui";
import { createDomain } from "../../store";
import { useNavigate } from "react-router";
import { Submit } from "../ui";

export function DomainForm() {
  const navigate = useNavigate();

  const onSubmit = async (payload: HobDomainPayload) => {
    const domain = await createDomain(payload);
    navigate(`../${domain.id}`, { relative: "path", replace: true });
  };
  return (
    <FormProvider schema={HobDomainSchema} defaultValues={{ host: "" }}>
      <FormTag onSubmit={onSubmit}>
        <TextInput disableAutoComplete label="Domain name (no https)" name="host" placeholder="example.com" />
        <Submit disableClean disableInvalid />
      </FormTag>
    </FormProvider>
  );
}
