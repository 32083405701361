import {
  Icon,
  Loader,
  Offsetino,
  ResponsiveWrapper,
  SubheaderMain,
  TextMain,
  flexStyles,
  iconStyles,
  paddingStyles,
  textStyles,
} from "ui";
import classNames from "classnames";
import { Button } from "../ui";
import { useAfterImportRedirect } from "../../hooks";
import { useContactImport } from "../../store";
import { pluralize } from "../../helpers";

export function ImportSuccessful() {
  const { contactImport } = useContactImport();
  const { afterImportRedirect } = useAfterImportRedirect();

  const doneTo = afterImportRedirect || "/campaigns";

  if (!contactImport) {
    return <Loader />;
  }

  return (
    <ResponsiveWrapper
      className={classNames(flexStyles.grow, paddingStyles.p125, flexStyles.vert200, flexStyles.alignCenter)}
    >
      <Offsetino />
      <div className={classNames(flexStyles.vert050, flexStyles.alignCenter)}>
        <Icon className={iconStyles.successMedium} iconType="Success" />
        <SubheaderMain className={classNames(textStyles.center)}>Contacts imported successfully</SubheaderMain>
      </div>
      <TextMain className={classNames(textStyles.center)}>
        {contactImport.candidateCount} {pluralize("contact", contactImport.candidateCount)} imported to your account.
      </TextMain>
      <Offsetino />
      <Button className={classNames(flexStyles.alignSelfStretch)} buttonType="secondary" to={doneTo}>
        Done
      </Button>
    </ResponsiveWrapper>
  );
}
