import { useNavigate } from "react-router";
import { FormProvider, FormTag, Loader, TextArea, TextMain, heightStyles, paddingStyles } from "ui";
import { ContactPayload, ContactSchema } from "schemas";
import { Overlay, Submit } from "../ui";
import { updateContact, useContact } from "../../store";

export function Note() {
  const navigate = useNavigate();
  const goBack = () => navigate("..", { relative: "path" });
  const contact = useContact();

  if (!contact) {
    return <Loader full />;
  }

  const onSubmit = async (values: ContactPayload) => {
    await updateContact(contact.id, values);
    goBack();
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      header="Note"
      subtitle={<TextMain>You can add a note about {contact.name}. This won’t be seen by them.</TextMain>}
      backTo={{ to: "..", label: "Contact Card" }}
    >
      <FormProvider defaultValues={contact} schema={ContactSchema}>
        <FormTag onSubmit={onSubmit}>
          <TextArea className={heightStyles.height1000} label="Note" name="note" placeholder="Add a note" />
          <Submit disableClean cancelTo=".." />
        </FormTag>
      </FormProvider>
    </Overlay>
  );
}
