import { flexStyles, paddingStyles } from "ui";
import { useDomains } from "../../store";
import classNames from "classnames";
import { Button, Overlay } from "../ui";
import { DomainCard } from "./DomainCard";
import { EmptyDomains } from "./EmptyDomains";
import { Outlet } from "react-router";

export function DomainsSettings() {
  const { domains, loading } = useDomains();

  return (
    <>
      <Overlay
        className={classNames(paddingStyles.p125, flexStyles.gap125)}
        header="Custom Email Domains"
        backTo={{ to: "/more" }}
        loading={loading}
      >
        {domains.length === 0 && <EmptyDomains />}
        {domains.map((domain) => (
          <DomainCard key={domain.id} domain={domain} />
        ))}
        {domains.length !== 0 && (
          <Button buttonType="secondary" to="new">
            + ADD DOMAIN
          </Button>
        )}
      </Overlay>
      <Outlet />
    </>
  );
}
