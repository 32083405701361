import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import { Drawer, Icon, RawIcon, TextMain, flexStyles, paddingStyles, svgStyles } from "ui";
import { getUrlWithBack, useGoBack } from "../../hooks";
import { addContactImport, useContactGroup } from "../../store";
import { ContactImportType } from "schemas";
import { SuperCard } from "../ui";

export function AddMenu() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const contactGroup = useContactGroup();
  const onClose = useGoBack("..");

  const onImport = (type: ContactImportType) => async () => {
    const destinationContactGroupIds = contactGroup && !contactGroup?.autogeneratedType ? [contactGroup.id] : undefined;
    const contactImport = await addContactImport({ type, destinationContactGroupIds });
    navigate(getUrlWithBack(`/contactImports/${contactImport.id}/lists`, pathname));
  };

  return (
    <Drawer title="Add contacts" open onClose={onClose}>
      <div className={classNames(flexStyles.vert125, paddingStyles.pb250)}>
        <TextMain>How do you want to add?</TextMain>
        <div className={flexStyles.vert025}>
          <SuperCard
            to="../contacts/new"
            left={<Icon iconType="Account" />}
            title={<TextMain>Create a new contact</TextMain>}
          />
          <SuperCard
            onClick={onImport("csv")}
            left={<Icon className={svgStyles.square} iconType="FileCsv" />}
            title={<TextMain>Upload a .csv to import</TextMain>}
          />
          <SuperCard
            onClick={onImport("googleContacts")}
            left={<RawIcon className={svgStyles.square} iconType="GoogleContacts" />}
            title={<TextMain>Import Google Contacts</TextMain>}
          />
          <SuperCard
            to="mailto:support@popsend.com?subject=Please import my contacts into PopSend"
            left={<Icon iconType="Mail" />}
            title={<TextMain>Email us a file to import</TextMain>}
          />
        </div>
      </div>
    </Drawer>
  );
}
