import { Asserts, object, string } from "yup";

export const EmailSettingsSchema = object({
  fromLocal: string()
    .nullable()
    .when("fromDomain", {
      is: (fromDomain: string | null) => !!fromDomain,
      then: (schema) => schema.required("Sender email is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  fromDomain: string().nullable(),
  fromName: string().nullable(),
  replyToAddress: string().nullable(),
}).noUnknown();
export type EmailSettingsPayload = Asserts<typeof EmailSettingsSchema>;

export type PersistedEmailSettings = EmailSettingsPayload & {
  id?: string;
  resolvedReplyToEmail: string;
  resolvedFromName: string;
  resolvedFromEmail: string;
};
