import { useNavigate } from "react-router";
import { Drawer } from "ui";
import { DomainForm } from "./DomainForm";

export function NewDomainDrawer() {
  const navigate = useNavigate();

  return (
    <Drawer title="Add a new domain" open onClose={() => navigate("..")} containerId="litesite-confirm">
      <DomainForm />
    </Drawer>
  );
}
