import classNames from "classnames";
import { useLocation, useNavigate } from "react-router";
import { PersistedContactGroup } from "schemas";
import {
  Card,
  Description,
  DotMenu,
  DotMenuLink,
  flexStyles,
  heightStyles,
  paddingStyles,
  SmallTextMain,
  TextMain,
  textStyles,
  useConfirm,
} from "ui";
import { getUrlWithBack } from "../../hooks";
import { deleteContactGroup } from "../../store";

interface Props {
  contactGroup: PersistedContactGroup;
  tall?: boolean;
}
export function ContactGroupCard({ contactGroup, tall }: Props) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { confirm, Confirm } = useConfirm();

  const links: DotMenuLink[] = [
    {
      label: "Edit",
      iconType: "Edit",
      onClick: () => navigate(getUrlWithBack(`/contactGroups/${contactGroup.id}/edit`, pathname)),
    },
    {
      label: "Delete",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `Deleting the “${contactGroup.name}” group will remove it from your contact groups. The contacts within the group will remain unaffected and can still be accessed individually. Are you sure you want to delete this group?`,
          label: "Delete Group",
          animationType: "Thrash",
          onConfirm: async () => deleteContactGroup(contactGroup.id),
        });
      },
    },
  ];
  return (
    <Card
      className={classNames(paddingStyles.px100, paddingStyles.py075, flexStyles.gap0, flexStyles.justifyCenter, {
        [heightStyles.min375]: tall,
      })}
      onClick={() => navigate(`/contactGroups/${contactGroup.id}`)}
    >
      <Confirm />
      <div className={classNames(flexStyles.horiz025, flexStyles.alignCenter)}>
        <TextMain>{contactGroup.name}</TextMain>
        <SmallTextMain className={classNames(flexStyles.grow, textStyles.neutral400)}>
          ({contactGroup.contactCount})
        </SmallTextMain>
        {!contactGroup.autogeneratedType && <DotMenu links={links} />}
      </div>
      <Description className={textStyles.neutral400}>{contactGroup.description}</Description>
    </Card>
  );
}
