import { setDomain } from "./setDomain";
import { apiRequest } from "../apiRequest";
import { HobDomain } from "schemas";

export async function validateDomain(hobDomainId: number) {
  const { domain } = await apiRequest<{ domain: HobDomain }>({
    url: `domains/${hobDomainId}/validate`,
    method: "PUT",
  });
  setDomain(domain);

  return domain;
}
