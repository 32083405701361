import { useLocation } from "react-router";

export function useShowMenu(): boolean {
  const { pathname } = useLocation();

  const hasMenu = [
    "/more",
    "/content",
    "/forms",
    "/contacts",
    "/dashboard",
    "/campaigns",
    "/campaigns/draft",
    "/campaigns/sent",
  ].includes(pathname);

  return !!hasMenu;
}
