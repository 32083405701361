import {
  Business,
  Calendar,
  CalendarAvailability,
  CalendarSettings,
  DomainsSettings,
  Home,
  NewDomainDrawer,
  ShowDomain,
} from "../components/settings";
import { TimezoneForm } from "../components/business";
import { PrivateOutlet } from "./PrivateOutlet";

export const SettingsRoutes = [
  {
    path: "more",
    Component: PrivateOutlet,
    children: [
      {
        path: "",
        Component: Home,
      },
    ],
  },
  {
    path: "settings",
    Component: PrivateOutlet,
    children: [
      {
        path: "business",
        Component: Business,
      },
      {
        path: "business/timezone",
        Component: TimezoneForm,
      },
      {
        path: "calendars",
        Component: CalendarSettings,
      },
      {
        path: "calendars/synced",
        Component: Calendar,
      },
      {
        path: "calendars/availability",
        Component: CalendarAvailability,
      },
      {
        path: "domains",
        Component: DomainsSettings,
        children: [{ path: "new", Component: NewDomainDrawer }],
      },
      {
        path: "domains/:domainId",
        Component: ShowDomain,
      },
    ],
  },
];
