import { useLocation, useNavigate } from "react-router";

export function useBackLocation(fallback: string) {
  const back = useMaybeBackLocation();
  return back || fallback;
}

export function useMaybeBackLocation(): string | null {
  const { search } = useLocation();
  const back = new URLSearchParams(search).get("backTo");
  return back;
}

export function useGoBack(fallback: string) {
  const navigate = useNavigate();
  const back = useBackLocation(fallback);
  return () => {
    navigate(back, { relative: "path" });
  };
}
