import { Navigate, useLocation } from "react-router";
import { toast } from "react-toastify";
import { useZoomSetupRedirect } from "../../hooks";

export function Callback() {
  const location = useLocation();
  const { zoomSetupRedirect } = useZoomSetupRedirect();
  const { search } = location;

  const searchParams = new URLSearchParams(search);
  const errorMessage = searchParams.get("error");
  if (errorMessage) {
    toast.error(errorMessage);
  }

  return <Navigate to={zoomSetupRedirect || ""} />;
}
