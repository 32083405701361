import { BlockDestination, BlockKind, BlockPayload, TextBlockPayload, emptyBlock } from "schemas";
import { InlineEditor } from "./InlineEditor";
import { addBlock } from "../../../store";
import { EditWrapper } from "../../blocks";
import { useNavigate } from "react-router";
import { PropsWithDirtyBlock } from "../../../helpers";

interface Props extends PropsWithDirtyBlock {
  destination: BlockDestination;
  destinationIndex: number;
  kind: BlockKind | "HeadingBlock";
}
export function NewInlineBlock({ destination, destinationIndex, kind, setDirtyBlock, dirtyBlock }: Props) {
  const navigate = useNavigate();

  const closeItself = () => {
    setDirtyBlock(false);
    navigate(".");
  };

  const onSubmit = async (payload: BlockPayload) => {
    await addBlock({ destinationIndex: destinationIndex!, destination, payload });
    closeItself();
  };

  const newBlock =
    kind === "HeadingBlock" ? { ...emptyBlock("TextBlock"), text: "# " } : (emptyBlock(kind) as TextBlockPayload);

  const editor = (
    <InlineEditor
      dirtyBlock={dirtyBlock}
      setDirtyBlock={setDirtyBlock}
      block={newBlock}
      onSubmit={onSubmit}
      onCancel={closeItself}
    />
  );
  return <EditWrapper open onClick={() => {}} editElement={editor} />;
}
