import { useEffect } from "react";
import { toast } from "react-toastify";
import { useLocation } from "react-router";

const allowedErrors = ["access_denied", "calendar_access_denied", "interaction_required"];

export function useParamsToast() {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const toastMessage = searchParams.get("toast");
  const toastError = searchParams.get("error");

  useEffect(() => {
    if (toastMessage) toast(toastMessage);

    if (toastError && !allowedErrors.includes(toastError)) toast.error(toastError);
  }, [toastError, toastMessage]);
}
