import { Outlet } from "react-router";
import { SmallTextAccent, TextMain, flexStyles, widthStyles } from "ui";
import { PersistedTheme, getThemeName } from "schemas";
import { DefaultThemeCheckbox, FontPreview, ShuffleTheme, ThemeCircles } from "../themes";
import { Preview } from "../themes/Preview";
import classNames from "classnames";
import { IconLink, SuperCard } from "../ui";
import { useIdParam } from "../../hooks";
import { ButtonPreview } from "../themes/ButtonPreview";

interface Props {
  theme: PersistedTheme;
}
export function Home({ theme }: Props) {
  const campaignId = useIdParam("campaignId");
  const hasCampaign = !!campaignId;
  const { buttonBorderRadius, buttonTextAlignment } = theme;

  return (
    <>
      <SuperCard
        title={<SmallTextAccent>Theme</SmallTextAccent>}
        right={<IconLink iconType="Edit" to={`theme/${theme.id}`} />}
      >
        <div className={flexStyles.horiz}>
          <div className={classNames(flexStyles.vert050, flexStyles.alignStart, flexStyles.grow)}>
            <TextMain>{getThemeName(theme)}</TextMain>
            <ThemeCircles theme={theme} />
            <ShuffleTheme theme={theme} />
          </div>
          <Preview theme={theme} />
        </div>
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Font</SmallTextAccent>}
        right={<IconLink iconType="Edit" to={`theme/${theme.id}/font`} />}
      >
        <div className={flexStyles.horiz}>
          <div className={classNames(flexStyles.grow)}>
            <TextMain>{theme.fontFamily}</TextMain>
          </div>
          <FontPreview fontFamily={theme.fontFamily} className={widthStyles.width1000}>
            The quick brown fox jumps over the lazy dog.
          </FontPreview>
        </div>
      </SuperCard>
      <SuperCard
        title={<SmallTextAccent>Buttons</SmallTextAccent>}
        right={<IconLink iconType="Edit" to={`theme/${theme.id}/buttons`} />}
      >
        <div className={classNames(flexStyles.horiz, flexStyles.alignCenter)}>
          <TextMain className={flexStyles.grow}>
            {buttonBorderRadius}
            <br />
            {buttonTextAlignment === "Default" ? "Default (LTR or RTL)" : buttonTextAlignment}
          </TextMain>
          <ButtonPreview className={flexStyles.grow} theme={theme} />
        </div>
      </SuperCard>
      {hasCampaign && <DefaultThemeCheckbox theme={theme} />}
      <Outlet />
    </>
  );
}
