import { useNavigate } from "react-router";
import { Modal, iconStyles } from "ui";
import { PlanSelector } from "./PlanSelector";
import { useBackLocation } from "../../hooks";
import { HomeWrapper } from "../ui";
import { UpgradeYourExistence } from "./UpgradeYourExistence";

export function Upgrade() {
  const navigate = useNavigate();
  const backUrl = useBackLocation("/more");
  return (
    <Modal className={iconStyles.neutral0} onClose={() => navigate(backUrl)}>
      <UpgradeYourExistence />
      <HomeWrapper>
        <PlanSelector />
      </HomeWrapper>
    </Modal>
  );
}
