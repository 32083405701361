import { Link, useNavigate } from "react-router";
import { PersistedForm } from "schemas";
import { Description, DotMenu, DotMenuLink, TextAccent, useConfirm } from "ui";
import { deleteForm } from "../../store";
import { SuperCard } from "../ui";

interface Props {
  form: PersistedForm;
}
export function FormCard({ form }: Props) {
  const navigate = useNavigate();
  const answerCount = form.formAnswers.length;
  const hasAnswers = answerCount > 0;
  const { confirm, Confirm } = useConfirm();

  const links: DotMenuLink[] = [
    {
      label: "Preview Form",
      iconType: "Show",
      onClick: () => {
        window.open(form.url);
      },
    },
    {
      label: "Embed Form",
      iconType: "OpenInNew",
      onClick: () => navigate(`${form.id}/embed`),
    },
    {
      label: "Delete Form",
      iconType: "Delete",
      onClick: () => {
        confirm({
          text: `Form "${form.name}" will be permanently deleted. Are you sure?`,
          animationType: "Thrash",
          label: "Delete Form",
          onConfirm: async () => deleteForm(form.id),
        });
      },
    },
  ];

  if (hasAnswers) {
    links.unshift({
      label: "View responses",
      iconType: "ContactGroup",
      onClick: () => navigate(`/forms/${form.id}/responses`),
    });
  }

  return (
    <SuperCard to={`/forms/${form.id}`} title={<TextAccent>{form.name}</TextAccent>} right={<DotMenu links={links} />}>
      <Confirm />
      <Description>
        {form.questions.length} questions
        {form.usedInAppointments.length > 0 && (
          <>
            <br />
            Used in {form.usedInAppointments.join(", ")}
          </>
        )}
        {hasAnswers && (
          <>
            <br />
            <Link onClick={(e) => e.stopPropagation()} style={{ color: "inherit" }} to={`/forms/${form.id}/responses`}>
              View {answerCount} responses
            </Link>
          </>
        )}
      </Description>
    </SuperCard>
  );
}
