import { SignupPayload, UserPayload } from "schemas";
import { apiRequest } from "../apiRequest";

interface Args {
  payload: SignupPayload;
}
export async function signup({ payload }: Args): Promise<UserPayload | null> {
  try {
    const user = await apiRequest<UserPayload>({
      url: "auth/signup",
      method: "POST",
      body: JSON.stringify({
        ...payload,
        user: payload.user,
      }),
    });
    return user;
  } catch (e) {
    const error = new Error();
    // @ts-expect-error errors
    error.message = e.response.data.message;
    throw error;
  }
}
