import { DescriptionAccent, Icon, RawIcon, TextMain, flexStyles, paddingStyles } from "ui";
import { useNavigate } from "react-router";
import { CSSProperties } from "react";
import classNames from "classnames";
import { useUrlWithBack } from "../../hooks";
import { DetailsCard } from "../business";
import { postCustomerPortalSession, useCurrentBusiness, useOnBusinessPlan } from "../../store";
import { Overlay, SuperCard } from "../ui";

export function Business() {
  const navigate = useNavigate();
  const business = useCurrentBusiness();
  const onBusinessPlan = useOnBusinessPlan();
  const goToCustomerPanel = async () => {
    const url = await postCustomerPortalSession();
    window.location.href = url;
  };
  const upgradeLink = useUrlWithBack("/billing/upgrade");

  const onMembershipClick = async () => {
    if (onBusinessPlan) {
      goToCustomerPanel();
    } else {
      navigate(upgradeLink);
    }
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap050)}
      header="Account Settings"
      backTo={{ to: "/more" }}
    >
      <DetailsCard />
      <SuperCard
        to="timezone"
        right={<Icon iconType="ChevronRight" />}
        left={<Icon iconType="Timezone" />}
        title={<DescriptionAccent>Time zone</DescriptionAccent>}
      >
        <TextMain>{business.timeZone}</TextMain>
      </SuperCard>
      <div style={{ "--icon-button-width": "auto" } as CSSProperties}>
        <SuperCard
          onClick={onMembershipClick}
          right={<RawIcon iconType={onBusinessPlan ? "ChevronRight" : "UpgradeBadge"} />}
          left={<RawIcon iconType="Account" />}
          title={<DescriptionAccent>Membership</DescriptionAccent>}
        >
          <TextMain>{business.planName}</TextMain>
        </SuperCard>
      </div>
    </Overlay>
  );
}
