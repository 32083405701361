import classNames from "classnames";
import { Link, useLocation } from "react-router";
import {
  Animation,
  AnimationType,
  DescriptionAccent,
  TextAccent,
  borderStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  positionStyles,
  squareStyles,
  textStyles,
  transitionStyles,
} from "ui";
import { useBreakpoint } from "../../hooks";
import { Dot } from "./Dot";

interface Props {
  href: string;
  label: string;
  animation: AnimationType;
  hasDot?: boolean;
}
export function Item({ href, label, animation, hasDot = false }: Props) {
  const { pathname } = useLocation();
  const active = pathname === href;
  const tablet = useBreakpoint("mobile");
  const TextComponent = tablet ? TextAccent : DescriptionAccent;

  return (
    <Link
      to={href}
      className={classNames(
        flexStyles.vert025,
        flexStyles.alignCenter,
        textStyles.decorationNone,
        paddingStyles.p025,
        positionStyles.relative,
        transitionStyles.all200,
        textStyles.primary,
        {
          [textStyles.secondary300]: active,
          [borderStyles.topSecondary300Thick]: active,
          [marginStyles.mtNegative012]: active,
        },
      )}
    >
      {hasDot && (
        <Dot
          className={classNames(
            positionStyles.absolute,
            positionStyles.top087,
            positionStyles.right075,
            positionStyles.zIndex47,
          )}
        />
      )}
      <Animation
        animationType={animation}
        noAutoplay
        keepLastFrame
        togglePlay={active}
        className={classNames(squareStyles.square250)}
      />
      <TextComponent className={textStyles.lsColorTransition1000}>{label}</TextComponent>
    </Link>
  );
}
