import { IconButton, ResponsiveWrapper, TextMain, bgStyles, flexStyles, iconStyles, useConfirm } from "ui";
import { TransparentBottomWrapper } from "../ui";
import classNames from "classnames";
import { batchDeleteContacts, removeContactGroupMembershps, useContactGroup } from "../../store";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

interface Props {
  contactIds: string[];
  onDone: () => void;
}
export function BulkContactActions({ contactIds, onDone }: Props) {
  const navigate = useNavigate();
  const count = contactIds.length;
  const text = `${count} contacts`;
  const contactGroup = useContactGroup();
  const { confirm, Confirm } = useConfirm();
  const disabled = count === 0;

  const onDelete = async () => {
    if (
      await confirm({
        text: `You're about to delete ${count} contacts, are you sure?`,
        label: `delete ${count} contacts`,
        onConfirm: async () => batchDeleteContacts(contactIds),
      })
    ) {
      toast.success(`${count} contacts deleted`);
      onDone();
    }
  };

  const onAddToList = () => {
    navigate("addToList", { state: { contactIds } });
  };

  const onRemoveFromList = async () => {
    if (
      contactGroup &&
      (await confirm({
        text: `Are you sure you want to remove ${count} contacts from ${contactGroup.name} ? `,
        label: `Remove ${count} contacts`,
        onConfirm: async () => {
          await removeContactGroupMembershps({
            contactGroupId: contactGroup.id,
            payload: {
              contacts: contactIds,
            },
          });
        },
      }))
    ) {
      toast.success(`${count} contacts removed from the list`);
      onDone();
    }
  };

  return (
    <TransparentBottomWrapper sticky className={bgStyles.neutral0}>
      <ResponsiveWrapper>
        <Confirm />
        <div
          className={classNames(flexStyles.horiz250, flexStyles.alignCenter, {
            [iconStyles.neutral500]: !disabled,
            [iconStyles.neutral300]: disabled,
          })}
        >
          <TextMain className={flexStyles.grow}>{text}</TextMain>
          <IconButton label="Add to a list" iconType="ContactAdd" onClick={disabled ? undefined : onAddToList} />
          {!contactGroup?.autogeneratedType && (
            <IconButton
              label="Remove from this list"
              iconType="ContactRemove"
              onClick={disabled ? undefined : onRemoveFromList}
            />
          )}
          <IconButton label="Delete contacts" iconType="Delete" onClick={disabled ? undefined : onDelete} />
        </div>
      </ResponsiveWrapper>
    </TransparentBottomWrapper>
  );
}
