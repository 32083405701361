import { FormProvider, FormTag, Loader, TextInput, paddingStyles } from "ui";
import { updateEmailSettings, useEmailSettings } from "../../store";
import { Overlay, Submit } from "../ui";
import { EmailSettingsPayload, EmailSettingsSchema } from "schemas";
import { useNavigate } from "react-router";
import { CustomDomainInput } from "./CustomDomainInput";

export function SenderDetailsForm() {
  const { loading, emailSettings } = useEmailSettings();
  const navigate = useNavigate();
  if (loading || !emailSettings) {
    return <Loader />;
  }

  const onSubmit = async (payload: EmailSettingsPayload) => {
    await updateEmailSettings(payload);
    navigate("..", { relative: "path" });
  };

  return (
    <Overlay className={paddingStyles.p125} header="Edit your sender details" backTo={{ to: ".." }}>
      <FormProvider<EmailSettingsPayload> defaultValues={emailSettings} schema={EmailSettingsSchema}>
        <FormTag onSubmit={onSubmit}>
          <CustomDomainInput />
          <TextInput name="fromName" label="Custom email from name" placeholder={emailSettings.resolvedFromName} />
          <TextInput
            name="replyToAddress"
            label="Custom reply-to email address"
            placeholder={emailSettings.resolvedReplyToEmail}
          />
          <Submit disableInvalid cancelTo=".." label="Save" />
        </FormTag>
      </FormProvider>
    </Overlay>
  );
}
