import classNames from "classnames";
import { useIdParam } from "../../hooks";
import { useDomain, validateDomain } from "../../store";
import { Button, Overlay } from "../ui";
import { Card, HeaderMain, Icon, Loader, TextMain, flexStyles, iconStyles, paddingStyles, widthStyles } from "ui";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { validationIcon } from "./validationIcon";
import { verificationDescription } from "./verificationDescription";
import { DnsRecord } from "./DnsRecord";
import cfProxyImage from "../../../public/images/cf-proxy-off.png";

export function ShowDomain() {
  const navigate = useNavigate();
  const domainId = useIdParam("domainId");
  const { domain, loading } = useDomain(+domainId);

  if (!loading && !domain) {
    toast.warn("Domain not found", { autoClose: 2000 });
    navigate("..", { relative: "path" });
    return;
  }

  if (!domain) {
    return <Loader />;
  }

  const { id, host, dns, valid, dnsProvider } = domain;

  const triggerValidation = async () => {
    await validateDomain(id);
  };

  return (
    <Overlay
      className={classNames(paddingStyles.p125, flexStyles.gap050)}
      header={host}
      backTo={{ to: "..", label: "custom domains" }}
    >
      <div
        className={classNames(flexStyles.horiz050, {
          [iconStyles.successMedium]: valid,
          [iconStyles.warningMedium]: !valid,
        })}
      >
        <Icon iconType={validationIcon(valid)} />
        <TextMain>{verificationDescription(valid)}</TextMain>
      </div>
      <HeaderMain>DNS Records</HeaderMain>
      {dnsProvider === "cloudflare" && (
        <Card className={paddingStyles.p125}>
          <TextMain>
            When setting cloudflare CNAME records turn off Cloudflare Proxy for CNAME records for quicker DNS records
            propagation.
          </TextMain>
          <img src={cfProxyImage} className={widthStyles.width1000} />
        </Card>
      )}
      <DnsRecord name="dkim1" record={dns.dkim1} />
      <DnsRecord name="dkim2" record={dns.dkim2} />
      <DnsRecord name="mail_cname" record={dns.mail_cname} />
      <Button buttonType="secondary" onClick={triggerValidation}>
        Validate DNS records
      </Button>
    </Overlay>
  );
}
