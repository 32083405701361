import { EmailLocalInput, Select, TextMain } from "ui";
import { useDomains } from "../../store";
import { Link } from "../ui";
import { useWatch } from "react-hook-form";

export function CustomDomainInput() {
  const domainFieldName = "fromDomain";
  const fromFieldName = "fromLocal";
  const { domains } = useDomains();

  const validDomains = domains.filter((domain) => domain.valid);
  const hasDomains = validDomains.length > 0;
  const domainValue = useWatch({ name: domainFieldName });

  const domainOptions = [
    { label: "Popsend domain", value: null },
    ...validDomains.map((d) => ({ label: d.host, value: d.host })),
  ];

  return (
    <>
      {!hasDomains && (
        <TextMain>
          Want to send from your domain? <Link to="/settings/domains">Set it up now!</Link>
        </TextMain>
      )}
      {hasDomains && <Select name={domainFieldName} options={domainOptions} />}
      {domainValue && <EmailLocalInput domain={domainValue} name={fromFieldName} label="Sender email address" />}
    </>
  );
}
