import { useWatch } from "react-hook-form";
import { useNavigate } from "react-router";
import { AppointmentPayload, ReturnPromptDaysOptions } from "schemas";
import { Strong, flexStyles, positionStyles } from "ui";
import classNames from "classnames";

import { ReminderCard } from "./ReminderCard";
import styles from "./Automation.module.css";
import { StepProps } from "./steps";
import { updateAppointment } from "../../store";

export function Automation({ id }: StepProps) {
  const navigate = useNavigate();

  const appointment = useWatch() as AppointmentPayload;
  const { reminders } = appointment;
  const returnTimeString = ReturnPromptDaysOptions.find((option) => option.value === reminders.ReturnPrompt?.days);

  const update = async () => {
    await updateAppointment(id!, appointment);
  };

  return (
    <div className={classNames(flexStyles.vert250, positionStyles.relative)}>
      <div className={styles.theLine} />
      <ReminderCard
        iconType="Reminder"
        label="Reminder"
        name="UpcomingBooking"
        description={
          <>
            Notifies and reminds the attendees of their upcoming appointments <Strong>24 hours before</Strong> the
            appointment.
          </>
        }
        onClick={() => update().then(() => navigate(`/appointments/${id}/automation/UpcomingBooking`))}
      />
      <ReminderCard iconType="Event" label="Appointment" />
      <ReminderCard
        iconType="Thanks"
        name="ThankYou"
        label="Thanks"
        description={
          <>
            Automatically sends to attendees <Strong>1 hour after</Strong> their appointment.
          </>
        }
        onClick={() => update().then(() => navigate(`/appointments/${id}/automation/ThankYou`))}
      />
      <ReminderCard
        iconType="FollowUp"
        name="ReturnPrompt"
        label="Follow up"
        description={
          <>
            Builds relationships with your attendees with a follow-up message{" "}
            <Strong>{returnTimeString?.label || "1 week"} after</Strong> their appointment.
          </>
        }
        onClick={() => update().then(() => navigate(`/appointments/${id}/automation/ReturnPrompt`))}
      />
    </div>
  );
}
