import { FormProvider, FormTag, Loader, Progress, TextArea, heightStyles, paddingStyles } from "ui";
import { useNavigate } from "react-router";
import { CampaignPromptPayload, CampaignPromptSchema, EmptyCampaignPrompt } from "schemas";
import { Overlay, Submit } from "../ui";
import { useIdParam } from "../../hooks";
import { createPrompt, usePrompt } from "../../store";

export function Generate() {
  const navigate = useNavigate();
  const promptId = useIdParam("promptId");
  const { prompt, loading } = usePrompt(promptId);

  if (loading) {
    return <Loader />;
  }

  const onClose = () => {
    navigate("..");
  };

  if (prompt && prompt.type !== "Campaign") {
    onClose();
    return;
  }

  const onSubmit = async (payload: CampaignPromptPayload) => {
    const prompt = await createPrompt({ payload });
    navigate(`/campaigns/generate/${prompt.id}/subjects`);
  };

  return (
    <Overlay
      className={paddingStyles.p125}
      backTo={{ to: "..", label: "Campaigns List" }}
      header="Create an email campaign with 🤖"
      subHeaderElement={<Progress current={1} total={3} />}
    >
      <FormProvider<CampaignPromptPayload>
        defaultValues={(prompt as CampaignPromptPayload) || EmptyCampaignPrompt}
        schema={CampaignPromptSchema}
      >
        <FormTag<CampaignPromptPayload> onSubmit={onSubmit}>
          <TextArea className={heightStyles.min500} name="who" label="Describe yourself *" />
          <TextArea className={heightStyles.min500} name="audience" label="Describe your audience" />
          <TextArea className={heightStyles.min500} name="what" label="Describe your email campaign *" />
          <Submit label="Generate 🤖" />
        </FormTag>
      </FormProvider>
    </Overlay>
  );
}
