import classNames from "classnames";
import { useNavigate } from "react-router";
import { Drawer, TextMain, bgStyles, flexStyles, paddingStyles } from "ui";
import { AddGrid } from "./AddGrid";
import { AddCard } from "./AddCard";
import { EditableBlockKinds, InlineEditableBlockKinds } from "schemas";
import { useDestinationIndex, useIdParam } from "../../hooks";
import { useBlockConsumer } from "./AvailableBlocks";

export function AddDrawer() {
  const navigate = useNavigate();
  const pathBlockId = useIdParam("blockId");
  const onClose = () => {
    navigate("../..", { relative: "path" });
  };
  const destinationIndex = useDestinationIndex();
  const consumer = useBlockConsumer();
  const blockBase = pathBlockId ? "../../../.." : "../..";

  const inlineTo = (kind: InlineEditableBlockKinds | "HeadingBlock") =>
    `${blockBase}/newInline/${destinationIndex}/${kind}`;
  const to = (kind: EditableBlockKinds) => `${blockBase}/new/${destinationIndex}/${kind}`;

  return (
    <Drawer className={bgStyles.neutral100} onClose={onClose} title="Add block" open>
      <div className={classNames(paddingStyles.py125, flexStyles.vert200)}>
        <div className={flexStyles.vert125}>
          <TextMain>Content Blocks</TextMain>
          <AddGrid open={true}>
            <AddCard consumer={consumer} kind="TextBlock" unthemed to={inlineTo("TextBlock")} iconType="TextFields">
              Text
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="HeadingBlock"
              unthemed
              to={inlineTo("HeadingBlock")}
              iconType="FormatHeadingH1"
            >
              Heading
            </AddCard>
            <AddCard consumer={consumer} kind="ImageBlock" unthemed to={inlineTo("ImageBlock")} iconType="Image">
              Image
            </AddCard>
            <AddCard consumer={consumer} kind="VideoBlock" unthemed to={inlineTo("VideoBlock")} iconType="Video">
              Video
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="VideoLinkBlock"
              unthemed
              to={inlineTo("VideoLinkBlock")}
              iconType="Video"
            >
              Video
            </AddCard>
            <AddCard consumer={consumer} kind="LinkBlock" unthemed to={inlineTo("LinkBlock")} iconType="Link">
              Button
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="PageBlock"
              unthemed
              to={`/pages/new/${destinationIndex}`}
              iconType="PageBlock"
            >
              Page
            </AddCard>
            <AddCard consumer={consumer} kind="FormLinkBlock" unthemed to={to("FormLinkBlock")} iconType="FormsSymbol">
              Form
            </AddCard>
          </AddGrid>
        </div>
        <div className={flexStyles.vert125}>
          <TextMain>Contact Blocks</TextMain>
          <AddGrid open={true}>
            <AddCard consumer={consumer} kind="EmailBlock" unthemed to={inlineTo("EmailBlock")} iconType="Mail">
              Email
            </AddCard>
            <AddCard consumer={consumer} kind="PhoneBlock" unthemed to={inlineTo("PhoneBlock")} iconType="PhoneEnabled">
              Phone
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="SocialLinksBlock"
              unthemed
              to={to("SocialLinksBlock")}
              iconType="SocialTiktok"
            >
              Social Media
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="AppointmentBlock"
              unthemed
              to={`/appointments/new/${destinationIndex}`}
              iconType="Calendar"
            >
              Appointment
            </AddCard>
            <AddCard
              consumer={consumer}
              kind="AppointmentLinkBlock"
              unthemed
              to={to("AppointmentLinkBlock")}
              iconType="Calendar"
            >
              Appointment
            </AddCard>
          </AddGrid>
        </div>
      </div>
    </Drawer>
  );
}
