import { useForms } from "../../store";
import { Loader, TextAccent, flexStyles } from "ui";
import { BottomWrapper, Button, RadioCard } from "../ui";
import { useState } from "react";
import { FormLinkBlockPayload } from "schemas";

interface Props {
  initialValues: FormLinkBlockPayload;
  onSubmit: (payload: FormLinkBlockPayload) => Promise<void>;
  cancelPath: string;
}

export function FormLinkForm({ initialValues, onSubmit, cancelPath }: Props) {
  const { forms, loading } = useForms();
  const [checked, setChecked] = useState<string | null>(initialValues.formId);
  const onSelect = async (id: string) => {
    setChecked(id);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className={flexStyles.vert050}>
        {forms.length === 0 && (
          <>
            <TextAccent>No forms found</TextAccent>
            <Button to="/forms/new" buttonType="secondary">
              Create a form first
            </Button>
          </>
        )}
        {forms.map((form) => (
          <RadioCard
            key={form.id}
            onClick={() => onSelect(form.id)}
            title={<TextAccent>{form.name}</TextAccent>}
            checked={checked === form.id}
          ></RadioCard>
        ))}
      </div>
      <BottomWrapper>
        <Button disabled={!checked} onClick={() => checked && onSubmit({ kind: "FormLinkBlock", formId: checked })}>
          Save
        </Button>
        <Button buttonType="tertiary" to={cancelPath}>
          Cancel
        </Button>
      </BottomWrapper>
    </>
  );
}
