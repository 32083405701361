import { useNavigate } from "react-router";
import { FontFamilyValues, ThemePayload, ThemeSchema } from "schemas";
import { FormTag, FormProvider, Drawer, flexStyles } from "ui";
import { Submit } from "../ui";
import { updateTheme, useTheme } from "../../store";
import { FontOption } from "./FontOption";
import { BigFontPreview } from "./BigFontPreview";

export function Fonts() {
  const navigate = useNavigate();
  const theme = useTheme();
  const onClose = () => navigate("../../..", { relative: "path" });

  const onSubmit = async (payload: ThemePayload) => {
    await updateTheme(theme.id, payload);
    onClose();
  };

  return (
    <Drawer onClose={onClose} title="Change font family" open>
      <FormProvider<ThemePayload> defaultValues={theme} schema={ThemeSchema}>
        <FormTag<ThemePayload> onSubmit={onSubmit}>
          <div className={flexStyles.vert200}>
            <BigFontPreview name="fontFamily" />
            <div className={flexStyles.vert050}>
              {FontFamilyValues.map((fontFamily) => (
                <FontOption key={`ff-${fontFamily}`} fontFamily={fontFamily} name="fontFamily" />
              ))}
            </div>
          </div>
          <Submit cancelTo="../../.." label="apply changes" disableInvalid />
        </FormTag>
      </FormProvider>
    </Drawer>
  );
}
