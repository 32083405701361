import { Drawer, Loader } from "ui";
import { useForm } from "../../store";
import { EmbedCode } from "./EmbedCode";
import { useNavigate } from "react-router";

export function EmbedForm() {
  const navigate = useNavigate();
  const { form } = useForm();

  if (!form) {
    return <Loader />;
  }

  return (
    <Drawer title="Embed form" open onClose={() => navigate("/forms")} containerId="litesite-confirm">
      <EmbedCode form={form} />
    </Drawer>
  );
}
