import { useLocation } from "react-router";
import { useLocalStorage } from "react-use";

export function useCustomerPaymentsRedirect() {
  const { pathname } = useLocation();
  const [customerPaymentsRedirect, setPaymentsRedirect, removeCustomerPaymentsRedirect] =
    useLocalStorage<string>("customer-payments-redirect");

  const setCustomerPaymentsRedirect = () => {
    setPaymentsRedirect(pathname);
  };

  return {
    customerPaymentsRedirect,
    setCustomerPaymentsRedirect,
    removeCustomerPaymentsRedirect,
  };
}
