import ReactSelect from "react-select";
import { AvailableValues } from "./AvailableValues";
import { BaseProps } from "./Props";
import { SelectOption } from "./SelectOption";
import { Colors, FontFamily, FontFamilyValues, fontFamilyCSS } from "schemas";
import { textStyles } from "../../styles";

export function BaseSelect<T extends AvailableValues>({ options, raw, placeholder, value, onChange }: BaseProps<T>) {
  const selectValue = options.find((option: SelectOption<T>) => option.value === value);

  const controlStyles = raw
    ? {
        background: "none",
        border: "none",
        boxShadow: "none",
        outline: "none",
        minHeight: "auto",
      }
    : {
        minHeight: "3.125rem",
        boxShadow: "none",
      };

  return (
    <ReactSelect
      isSearchable={false}
      options={options}
      onChange={onChange}
      value={selectValue}
      placeholder={placeholder}
      classNames={{ option: () => textStyles.fontTheme }}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,
          ...controlStyles,
        }),
        valueContainer: (baseStyles) => ({
          ...baseStyles,
          fontSize: "0.875rem",
          lineHeight: "1.25rem",
          padding: "0 0 0 0.75rem",
          "--heading-label-display": "none",
        }),
        singleValue: (baseStyles) => ({
          ...baseStyles,
          color: "var(--litesite-text-color)",
        }),
        menu: (baseStyles) => ({ ...baseStyles, minWidth: "15rem" }),
        option: (baseStyles, ctx) => {
          const value = ctx.children;
          const fontStyles = FontFamilyValues.includes(value?.toString() as FontFamily)
            ? fontFamilyCSS(value as FontFamily)
            : {};
          return {
            ...baseStyles,
            ...fontStyles,
          };
        },
        menuList: (baseStyles) => ({
          ...baseStyles,
          "--litesite-text-color": Colors.Neutral600,
          "--icon-color": Colors.Neutral600,
        }),
        indicatorSeparator: () => ({ display: "none" }),
        dropdownIndicator: (baseStyles, state) => ({
          ...baseStyles,
          transform: state.isFocused ? "rotate(180deg)" : "",
          margin: "-5px 0",
        }),
      }}
      theme={(theme) => ({
        ...theme,
        indicatorSeparator: () => ({ display: "none" }),
        colors: {
          ...theme.colors,
          primary: Colors.Secondary300,
          neutral20: Colors.Neutral400,
        },
      })}
    />
  );
}
