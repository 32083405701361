import { useNavigate } from "react-router";
import { useIdParam } from "../../hooks";
import {
  HeaderMain,
  Loader,
  Progress,
  ThemedWrapper,
  borderStyles,
  flexStyles,
  marginStyles,
  paddingStyles,
  textStyles,
  widthStyles,
} from "ui";
import classNames from "classnames";
import { useState } from "react";
import { ReadonlyBlock } from "../blocks";
import { Button, Overlay } from "../ui";
import { regenerateCampaign, useCampaign, useCurrentBusiness, useTheme } from "../../store";
import { useCampaignElements } from "../../store/campaigns/useCampaignElements";

export function Preview() {
  const navigate = useNavigate();
  const [regenerating, setRegenerating] = useState(false);
  const promptId = useIdParam("promptId");
  const business = useCurrentBusiness();
  const { campaign } = useCampaign();
  const elements = useCampaignElements(campaign?.id);
  const theme = useTheme(campaign?.themeId);

  if (!campaign || !theme) {
    return <Loader />;
  }

  const onRegenerate = async () => {
    setRegenerating(true);
    await regenerateCampaign(campaign.id, promptId, { content: "Please suggest something else" });
    setRegenerating(false);
  };

  const onContinue = () => {
    navigate(`/campaigns/${campaign.id}/content`);
  };

  return (
    <Overlay
      full
      backTo={{ to: `/campaigns/generate/${promptId}`, label: "Prompt" }}
      header="Preview"
      title={campaign?.subject}
      subHeaderElement={<Progress current={3} total={3} />}
    >
      <ThemedWrapper className={classNames(flexStyles.grow)} theme={theme}>
        <div
          className={classNames(
            flexStyles.vert100,
            paddingStyles.p125,
            paddingStyles.py200,
            widthStyles.full,
            widthStyles.maxWidth4225,
            marginStyles.centered,
          )}
        >
          <img
            src={`${business.logo}/logo`}
            width="160"
            height="160"
            className={classNames(borderStyles.radiusRound, marginStyles.centered)}
            alt={`${business.businessName} logo`}
          />
          <HeaderMain className={textStyles.center}>{business.businessName}</HeaderMain>
          {!campaign || regenerating ? (
            <Loader />
          ) : (
            <>
              {elements?.map(({ id, block }) => <ReadonlyBlock key={id} kind={block.kind} blockId={block._id} />)}
              <Button buttonType="secondary" onClick={onRegenerate}>
                Try something else
              </Button>
              <Button onClick={onContinue}>Continue with this draft</Button>
            </>
          )}
        </div>
      </ThemedWrapper>
    </Overlay>
  );
}
