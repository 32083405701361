import { SharedBusiness } from "schemas";
import { useState } from "react";
import classNames from "classnames";
import { BusinessDetailsCard, PopoverMenu, flexStyles } from "ui";
import { EditWrapper } from "../blocks";
import { useNavigate } from "react-router";

interface Props {
  business: SharedBusiness;
}
export function BusinessAddressPreview({ business }: Props) {
  const navigate = useNavigate();
  const onClick = () => {
    navigate("address", { preventScrollReset: true });
  };
  const [open, setOpen] = useState(false);

  const card = <BusinessDetailsCard business={business} onClick={onClick} />;

  const editElement = open && (
    <>
      {card}
      {business.address && <PopoverMenu open links={[{ label: "Edit", onClick, iconType: "Edit" }]} />}
    </>
  );

  return (
    <EditWrapper
      open={open}
      onClick={() => setOpen(!open)}
      className={classNames(flexStyles.alignCenter, flexStyles.gap050)}
      editElement={editElement}
    >
      {card}
    </EditWrapper>
  );
}
