import { Description, SquareCard, TextMain, bgStyles, borderStyles, flexStyles, marginStyles, textStyles } from "ui";
import { useNavigate } from "react-router";
import classNames from "classnames";
import { HomeWrapper, IconBox, IconBoxLink, UpgradeBox } from "../ui";
import { useBreakpoint, useCustomerPaymentsRedirect } from "../../hooks";
import { logout, useCustomerPaymentsSetup, useNewItems } from "../../store";
import { pluralize } from "../../helpers";

export function Home() {
  const navigate = useNavigate();
  const paymentsSetup = useCustomerPaymentsSetup();
  const { setCustomerPaymentsRedirect } = useCustomerPaymentsRedirect();
  const desktop = useBreakpoint("desktop");
  const { newItems } = useNewItems();
  const newBookingsCount = newItems && newItems.bookings.length;
  const newFormsCount = newItems && newItems.formAnswers.length;

  const onPaymentsClick = () => {
    setCustomerPaymentsRedirect();

    if (paymentsSetup) {
      navigate("/payments/retry");
    } else {
      navigate("/payments/connect");
    }
  };

  return (
    <>
      <UpgradeBox />
      <HomeWrapper>
        {!desktop && (
          <div
            className={classNames(
              flexStyles.horiz,
              flexStyles.justifyCenter,
              flexStyles.alignStretch,
              flexStyles.gap050,
            )}
          >
            <SquareCard className={flexStyles.basisHalf} iconType="FormsSymbol" onClick={() => navigate("/forms")}>
              <div className={classNames(flexStyles.vert025, flexStyles.alignCenter, textStyles.center)}>
                <TextMain>Forms</TextMain>
                {newFormsCount ? (
                  <Description className={textStyles.tenn}>
                    {newFormsCount} new {pluralize("submission", newFormsCount)}
                  </Description>
                ) : undefined}
              </div>
            </SquareCard>
            <SquareCard className={flexStyles.basisHalf} iconType="Calendar" onClick={() => navigate("/dashboard")}>
              <div className={classNames(flexStyles.vert025, flexStyles.alignCenter, textStyles.center)}>
                <TextMain>Appointments</TextMain>
                {newBookingsCount ? (
                  <Description className={textStyles.tenn}>
                    {newBookingsCount} new {pluralize("booking", newBookingsCount)}
                  </Description>
                ) : undefined}
              </div>
            </SquareCard>
          </div>
        )}
        <div className={classNames(flexStyles.vert, marginStyles.mxNegative125)}>
          <IconBoxLink title="Account Settings" iconType="Settings" to="/settings/business" />
          <IconBoxLink to="/settings/calendars" title="Calendar Settings" iconType="Calendar" />
          <IconBox onClick={onPaymentsClick} title="Payment Settings" iconType="Payments" />
          <IconBoxLink to="/settings/domains" title="Custom Domains" iconType="EmailNewsletter" />
          <IconBox
            className={classNames(bgStyles.none, borderStyles.none)}
            onClick={() => {
              window.open("mailto:dean@popsend.com");
            }}
            title="Send Feedback"
            iconType="Feedback"
          />
          <IconBox
            className={classNames(bgStyles.none, borderStyles.none)}
            title="Logout"
            iconType="Logout"
            onClick={() => {
              logout();
            }}
          />
        </div>
      </HomeWrapper>
    </>
  );
}
