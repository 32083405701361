import { HobDomain } from "schemas";
import { apiRequest } from "../apiRequest";
import { HookLoading } from "../types";
import { useEffect, useState } from "react";
import { useLiteSiteStore } from "../store";
import { setDomain } from "./setDomain";

async function getDomain(id: number) {
  try {
    const { domain } = await apiRequest<{ domain: HobDomain }>({ url: `domains/${id}` });
    setDomain(domain);
  } catch {
    // not found
  }
}

export function useDomain(id: number): HookLoading<{ domain: HobDomain | undefined }> {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getDomain(id).then(() => setLoading(false));
  }, [id]);
  const domain = useLiteSiteStore(({ domains }) => domains.get(id));

  return { loading, domain };
}
