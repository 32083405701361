import { useEffect, useState } from "react";
import { apiRequest } from "../apiRequest";

type LimitResult = { overLimit: boolean; overSendableThreshold: boolean };
async function fetchContactsLimit(quantity?: number): Promise<LimitResult> {
  const params = new URLSearchParams(quantity ? { quantity: quantity.toString() } : {});
  const limitResult = await apiRequest<LimitResult>({
    url: `contacts/limit?${params.toString()}`,
  });

  return limitResult;
}

export function useOverContactsLimit(quantity?: number): LimitResult | null {
  const [overLimit, setOverLimit] = useState<boolean>();
  const [overSendableThreshold, setOverSendableThreshold] = useState<boolean>();

  useEffect(() => {
    if (quantity !== undefined) {
      fetchContactsLimit(quantity).then(({ overLimit, overSendableThreshold }) => {
        setOverLimit(overLimit);
        setOverSendableThreshold(overSendableThreshold);
      });
    }
  }, [quantity]);

  return overLimit === null ? null : { overLimit: !!overLimit, overSendableThreshold: !!overSendableThreshold };
}
