import { PersistedContact } from "schemas";
import { useLiteSiteStore } from "../store";
import { compact } from "es-toolkit";
import { useWatch } from "react-hook-form";

export function useCampaignContacts(): PersistedContact[] {
  const campaignContactIds: string[] = useWatch({ name: "contactIds" });
  const contacts = useLiteSiteStore((state) => state.contacts);

  return compact(campaignContactIds.map((id) => contacts.get(id)));
}
