import { useNavigate } from "react-router";
import { BlockDestination, BlockPayload, EditableBlockKinds, emptyBlock } from "schemas";
import { paddingStyles } from "ui";
import { useBlockKindParam, useDestinationIndex, useIdParam } from "../../hooks";
import { BlockFormComponents } from "./blockForms";
import { addBlock, useCampaign, useHomePage, usePage } from "../../store";
import { Overlay } from "../ui";
import { useBlockConsumer } from "./AvailableBlocks";

const Titles: Record<EditableBlockKinds, string> = {
  AppointmentLinkBlock: "Create Appointment Link Block",
  FormLinkBlock: "Create Form Link Block",
  SocialLinksBlock: "Create Social Links Block",
};

export function New() {
  const navigate = useNavigate();
  const destinationIndex = useDestinationIndex();
  const blockKind = useBlockKindParam();
  const consumer = useBlockConsumer();
  const homePage = useHomePage();
  const pageId = useIdParam("pageId");
  const page = usePage(pageId);
  const { campaign } = useCampaign();

  const onSubmit = async (payload: BlockPayload) => {
    let destination: BlockDestination;
    switch (consumer) {
      case "Campaign":
        destination = { entity: campaign!, type: "Campaign" };
        break;
      case "Page":
        destination = { entity: page!, type: "Page" };
        break;
      case "HomePage":
        destination = { entity: homePage!, type: "Page" };
        break;
    }
    await addBlock({ destinationIndex, destination, payload });

    navigate("../../../..", { relative: "path" });
  };

  const Form = BlockFormComponents[blockKind];

  return (
    <Overlay className={paddingStyles.p125} backTo={{ to: "..", label: "Block Selection" }} header={Titles[blockKind]}>
      <Form initialValues={emptyBlock(blockKind)} onSubmit={onSubmit} cancelPath="../../../.." />
    </Overlay>
  );
}
