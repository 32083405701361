import classNames from "classnames";
import { PropsWithChildren } from "react";
import { Link as RouterLink } from "react-router";
import { OnClickHandler, PropsWithClassName, bgStyles, borderStyles, pointerStyles } from "ui";
import { ButtonLink } from "./ButtonLink";

export interface Props extends PropsWithClassName, PropsWithChildren {
  to?: string;
  href?: string;
  onClick?: OnClickHandler;
  disabled?: boolean;
  target?: "_blank";
  htmlButtonType?: "button" | "submit";
  preventScrollReset?: boolean;
}

export function RawLink({
  href,
  target,
  disabled = false,
  to,
  children,
  onClick,
  className,
  htmlButtonType = "button",
  preventScrollReset,
}: Props) {
  if (href) {
    return (
      <a className={className} target={target} href={href}>
        {children}
      </a>
    );
  }

  if (onClick || htmlButtonType === "submit") {
    return (
      <ButtonLink
        disabled={disabled}
        className={classNames(bgStyles.none, borderStyles.none, { [pointerStyles.cursor]: !disabled }, className)}
        htmlButtonType={htmlButtonType}
        onClick={onClick}
      >
        {children}
      </ButtonLink>
    );
  }

  if (to) {
    return (
      <RouterLink preventScrollReset={preventScrollReset} className={className} relative="path" to={to}>
        {children}
      </RouterLink>
    );
  }

  throw new Error("Misconfigured link");
}
