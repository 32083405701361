import { HobDomain } from "schemas";
import { useLiteSiteStore } from "../store";

export function setDomain(domain: HobDomain) {
  useLiteSiteStore.setState(({ domains }) => {
    const newDomains = new Map(domains);

    return {
      domains: newDomains.set(domain.id, domain),
    };
  });
}
