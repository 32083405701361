import { HobDomainPayload, HobDomain } from "schemas";
import { setDomain } from "./setDomain";
import { apiRequest } from "../apiRequest";

export async function createDomain(payload: HobDomainPayload) {
  const { domain } = await apiRequest<{ domain: HobDomain }>({
    url: "domains",
    method: "POST",
    body: JSON.stringify(payload),
  });
  setDomain(domain);

  return domain;
}
