import classNames from "classnames";
import { OnClickHandler, PropsWithClassName } from "./types";
import { bgStyles, borderStyles, flexStyles, overflowStyles, paddingStyles, textStyles } from "./styles";
import { PropsWithChildren } from "react";
import { IconButton } from "./IconButton";
import { useCopyToClipboard } from "react-use";

interface Props extends PropsWithChildren, PropsWithClassName {
  copyable?: boolean;
  onCopy?: OnClickHandler;
}
export function Code({ children, className, copyable, onCopy }: Props) {
  const [, copyToClipboard] = useCopyToClipboard();
  const copy = () => {
    copyToClipboard(children?.toString() || "");
    onCopy && onCopy();
  };
  return (
    <div
      className={classNames(
        borderStyles.neutral400,
        borderStyles.radius025,
        bgStyles.neutral200,
        paddingStyles.p050,
        flexStyles.horiz050,
        flexStyles.alignCenter,
        className,
      )}
    >
      <div className={classNames(textStyles.fontCode, textStyles.size075, flexStyles.grow, overflowStyles.scrollX)}>
        {children}
      </div>
      {copyable && <IconButton onClick={copy} iconType="Copy" />}
    </div>
  );
}
