import { toast } from "react-toastify";
import { useCopyToClipboard } from "react-use";
import { PersistedForm } from "schemas";
import { Button, Code, Description, flexStyles } from "ui";
import { useCurrentBusiness } from "../../store";

interface Props {
  form: PersistedForm;
}
export function EmbedCode({ form }: Props) {
  const business = useCurrentBusiness();
  const [, copyToClipboard] = useCopyToClipboard();
  const iframeCode = `<iframe
      style="border: none"
      height="500px"
      width="400px"
      src="${business.relativeUrl}/forms/${form.publicUuid}/embed"
  />`;

  const copyCode = () => {
    copyToClipboard(iframeCode);
    toast.info("Copied!");
  };

  return (
    <div className={flexStyles.vert050}>
      <Description>Embed &quot;{form.name}&quot; form in your website by adding the code below to it</Description>
      <Code>{iframeCode}</Code>
      <Button onClick={copyCode}>Copy Embed Code</Button>
    </div>
  );
}
