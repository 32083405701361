import { useLocation, useNavigate } from "react-router";

interface Hook {
  searchQuery: string;
  setSearchQuery: (searchQuery: string | undefined) => void;
  searchQueryExists: boolean;
}

const queryParam = "query";
export function useSearchQuery(): Hook {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  function setSearchQuery(newQuery: string | undefined) {
    navigate(pathname, {
      replace: true,
      state: { [queryParam]: newQuery || "" },
    });
  }

  const searchQueryExists = !!state && !!state[queryParam];

  return {
    searchQuery: (state && state[queryParam]) || "",
    setSearchQuery,
    searchQueryExists,
  };
}
