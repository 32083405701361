import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router";

import { TextMain } from "ui";
import { App } from "./components/App";
import { useEffect } from "react";
import { AuthRoutes } from "./pages/Auth";
import { CampaignsRoutes } from "./pages/Campaigns";
import { DashboardRoutes } from "./pages/Dashboard";
import { AppointmentRoutes } from "./pages/Appointments";
import { BookingRoutes } from "./pages/Bookings";
import { ContentRoutes } from "./pages/Content";
import { FormRoutes } from "./pages/Forms";
import { ZoomRoutes } from "./pages/Zoom";
import { SettingsRoutes } from "./pages/Settings";
import { PaymentsRoutes } from "./pages/Payments";
import { ContactRoutes } from "./pages/Contacts";
import { PagesRoutes } from "./pages/Pages";
import { BusinessRoutes } from "./pages/Business";
import { BillingRoutes } from "./pages/Billing";
import { NotFound } from "./pages/NotFound";

const { REACT_APP_SENTRY_DSN: SENTRY_DSN, REACT_APP_SENTRY_ENV: SENTRY_ENV } = import.meta.env;

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    Component: App,
    errorElement: <TextMain>Something went wrong, please try again</TextMain>,
    children: [
      {
        path: "",
        element: <Navigate replace to="/campaigns" />,
      },
      AuthRoutes,
      CampaignsRoutes,
      DashboardRoutes,
      AppointmentRoutes,
      BookingRoutes,
      ContentRoutes,
      FormRoutes,
      ZoomRoutes,
      ...SettingsRoutes,
      PaymentsRoutes,
      ...ContactRoutes,
      PagesRoutes,
      BusinessRoutes,
      BillingRoutes,
      {
        path: "*",
        Component: NotFound,
      },
    ],
  },
]);

const container = document.querySelector("#root");
const root = createRoot(container!);
root.render(<RouterProvider router={router} />);
